export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(userData) {
  return (dispatch) => dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      userData,
    },
  });
}

export function setUserData(userData) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      userData,
    },
  });
}

export function logout() {
  return (dispatch) => dispatch({
    type: LOGOUT,
  });
}

export function register() {
  return true;
}
