import axios from '../../utils/axios';

const postLogin = async ({
  email, password, source,
}) => {
  const response = await axios.post('/api/auth/login', {
    email,
    password,
    source,
  });

  return response.data;
};

const postRegister = async ({
  name, lastname, email, role,
}) => {
  const response = await axios.post('/api/auth/register', {
    name, lastname, email, role,
  });

  return response.data;
};

const postLogout = async (id) => {
  const response = await axios.post('/api/auth/logout', {
    userId: id,
  });

  return response.data;
};

// RECOVER PASSWORD
const postRecoverPassword = async (email) => {
  const response = await axios.post('/api/auth/recover-password', {
    email,
  });

  return response.data;
};

// UPDATE PASSWORD
const updatePassword = async ({ token, password, confirmPassword }) => {
  const response = await axios.post('/api/auth/reset-password', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

// USER VERIFICATION
const userVerification = async ({ token, password, confirmPassword }) => {
  const response = await axios.post('/api/auth/user-verification', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

export {
  postLogin,
  postRegister,
  postLogout,
  postRecoverPassword,
  updatePassword,
  userVerification,
};
