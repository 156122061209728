import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { login } from '../../actions/accountActions';
import AuthService from '../../services/authService';
import { postRecoverPassword } from '../../requests/api/auth';
import { getModuleInformationByClient } from '../../requests/api/modules';

import useStyles from './styles';
import CustomTextField from '../../components/Material/CustomTextfield';
import handleApiResponse from '../../utils/handleApiResponse';
import CustomButton from '../../components/Material/CustomButton';
import { setModule, resetModule } from '../../actions/sessionActions';

const POS = 'POS';

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (email) => {
    try {
      const response = await postRecoverPassword(email);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleLogin = async (email, password) => {
    const response = await AuthService.loginWithEmailAndPassword(email, password, POS);
    const { user } = response;
    if (response.success) {
      const moduleResponse = await getModuleInformationByClient({ clientId: user.clientId });
      const { searchedModule } = moduleResponse;
      const userData = {
        id: user.id,
        email: user.email,
        name: user.name,
        lastname: user.lastname,
        role: user.role,
        clientId: user.clientId,
      };
      dispatch(login(userData));
      dispatch(setModule(searchedModule));
      return;
    }
    throw new Error(response.errorMessage);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email requerido'),
        password: Yup.string().max(255).required('Contraseña requerida'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          dispatch(resetModule());
          await handleLogin(values.email, values.password);
          onSubmitSuccess();
        } catch (error) {
          const message = error.message || 'Ha ocurrido un error. Por favor intente nuevamente más tarde.';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box
            marginBottom='8px'
          >
            <CustomTextField
              error={Boolean(touched.email && errors.email)}
              // color="secondary"
              fullWidth
              // autoFocus
              helperText={touched.email && errors.email}
              placeholder="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
          </Box>
          <Box>
            <CustomTextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              color="secondary"
              helperText={touched.password && errors.password}
              placeholder="Contraseña"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
          </Box>
          <Box mt={2}>
            <CustomButton
              color="primary"
              disabled={isSubmitting}
              fullWidth
              type="submit"
              variant="contained"
              buttoncolor='blue'
            >
              Iniciar Sesión
            </CustomButton>
            {errors.submit && (
              <Box mt={3} display="flex" justifyContent="center">
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Button
            className={classes.resetPassword}
            onClick={() => handleResetPassword(values.email)}
          >
            ¿Olvidaste tu <b>contraseña</b>?
          </Button>
          {/* TODO: Descomentar si se permitirá el registro de usuarios fuera del Dashboard
          y crear una ruta que acepte password */}
          {/* <a href="/register">Registarse</a> */}
        </form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => { },
};

export default LoginForm;
